export const GetInvoices = `
query getInvoices($input: InvoicesInput) {
  getInvoices(input: $input) {
      issue_date
      period_range_start
      period_range_end
      total_amount
      currency
      product_name
      product_family_name
      public_url
    }
  }
`
