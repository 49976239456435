import React, { memo, useEffect, useState } from "react"
import { RichText } from "prismic-reactjs"
import isEqual from "lodash/isEqual"

import type { LayoutType, UserType } from "@lesmills/gatsby-theme-common"
import {
  ROUTES,
  formatDateTime,
  DATETIME_FORMAT,
  linkResolver,
  htmlSerializerUpdateStyle,
  Spinner,
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  isAffiliateUser,
  isResellerCustomerUser,
} from "@lesmills/gatsby-theme-common"

import InformationRow from "../InformationRow"
import { Link } from "gatsby"
import UpdateLabel from "../UpdateLabel"
import Container from "../Container"
import { API, graphqlOperation } from "aws-amplify"
import { GetInvoices } from "../../graphql/getInvoices"
import {
  getInvoicesStartDate,
  sortInvoices,
  DEFAULT_SORTING_TYPE,
} from "../../utils/invoices"
import loadable from "@loadable/component"

const PaymentHistoryTable = loadable(() => import("./PaymentHistoryTable"), {
  fallback: <Spinner />,
})

const Notification = loadable(() => import("@lesmills/gatsby-theme-common"), {
  fallback: <Spinner />,
  resolveComponent: components => components.Notification,
})

type Props = {|
  data: Object,
  lang: String,
  user: UserType,
  layout: LayoutType,
|}

const PaymentHistory = ({ data, lang, user = {}, layout }: Props) => {
  const [invoices, setInvoices] = useState([])
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const {
    current_subscription = {},
    next_billing_date = {},
    change_subscription_type = {},
    back_to_my_account = {},
    title = {},
    note = {},
    no_invoice_message = {},
    lmod_gi_err = {},
  } = data

  const subscription = user.lmodSubscription || {}

  const getInvoices = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(GetInvoices, {
          input: {
            status: "paid",
            start_date: getInvoicesStartDate(),
          },
        })
      )

      setInvoices(response.data.getInvoices)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      handleErrorWithPrismic(
        err.errors || [],
        lmod_gi_err.text,
        setError,
        data,
        SENTRY_ACTIONS.GET_INVOICES
      )
    }
  }

  useEffect(() => {
    getInvoices()
  }, [])

  const renderInvoices = () => {
    if (isLoading) {
      return (
        <div className="relative my-30 py-20">
          <Spinner />
        </div>
      )
    }

    if (error) return <Notification message={error.message} type="error" />

    return invoices.length ? (
      <>
        <div className="border border-gray-550 px-20 pb-20 flex justify-between mt-20">
          <div className="mt-10">
            <InformationRow
              label={current_subscription.text}
              value={
                subscription.product
                  ? subscription.product.name
                  : subscription.subscription_type
              }
            />
            <InformationRow
              label={next_billing_date.text}
              value={formatDateTime(
                subscription.current_period_ends_at,
                DATETIME_FORMAT.default,
                layout
              )}
            />
          </div>
          {!isAffiliateUser(user.lmodSubscription) &&
            !isResellerCustomerUser(user) &&
            (process.env.GATSBY_RT_05_04_2022_CHANGE_SUBSCRIPTION !== "true" ? (
              <Link
                className="underline text-base leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800 mt-20"
                to={ROUTES(lang).CHANGE_SUBSCRIPTION}
              >
                <UpdateLabel label={change_subscription_type.text} />
              </Link>
            ) : (
              <a
                href={
                  process.env.GATSBY_CHANGE_SUBSCRIPTION_LINK_NEW ||
                  `${process.env.GATSBY_GETTING_STARTED_URL}change-subscription/`
                }
                className="underline text-base leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800 mt-20"
              >
                <UpdateLabel label={change_subscription_type.text} />
              </a>
            ))}
        </div>
        <PaymentHistoryTable
          prismicContent={data}
          layout={layout}
          invoices={sortInvoices(invoices, DEFAULT_SORTING_TYPE)}
        />
      </>
    ) : (
      <div className="border-b border-gray-35 py-40">
        <RichText
          render={no_invoice_message.raw}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-base-light text-3xs"
          )}
        />
      </div>
    )
  }

  return (
    <Container
      title={title.text}
      backLink={{
        name: back_to_my_account.text,
        url: ROUTES(lang).CUSTOMER_ACCOUNT,
      }}
      classNames={{
        wrapper: " max-w-1200 md:px-10",
      }}
    >
      {renderInvoices()}
      <RichText
        render={note.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "max-w-448 leading-20 text-gray-130 text-3xs my-25 font-base-light"
        )}
      />
    </Container>
  )
}

export default memo(PaymentHistory, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
