import React from "react"

import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  ROUTES,
  PrivateRoute,
} from "@lesmills/gatsby-theme-common"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import PaymentHistory from "../components/PaymentHistory"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const PaymentHistoryPage = (props: Props) => {
  const { pageContext, data } = props || {}

  if (!data) {
    return null
  }

  const lang = pageContext.unPublishedLang || pageContext.lang

  const { prismicLayout = {}, prismicPaymentHistoryPage = {} } = data

  const pageContent = prismicPaymentHistoryPage.data || {}
  const layout = prismicLayout.data || {}

  const renderPaymentHistoryPage = ({ user, checkingSession }: UserType) => (
    <Layout data={layout} lang={lang} user={user} isLoading={checkingSession}>
      {renderSEO(pageContent, ROUTES(lang).PAYMENT_HISTORY, lang)}
      <PaymentHistory
        user={user}
        data={pageContent}
        layout={layout}
        lang={lang}
      />
    </Layout>
  )

  return (
    <PrivateRoute
      component={renderPaymentHistoryPage}
      lang={lang}
      {...props}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        logo {
          alt
          url
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        lmod_gu_err {
          text
        }
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
        body2 {
          ...LayoutBody2Months
        }
      }
    }
    prismicPaymentHistoryPage(lang: { eq: $lang }) {
      data {
        back_to_my_account {
          text
        }
        body {
          ... on PrismicPaymentHistoryPageBodyPaymentHistoryTableHeaders {
            items {
              column_id
              column_name {
                raw
                text
              }
            }
          }
        }
        change_subscription_type {
          raw
          text
        }
        current_subscription {
          raw
          text
        }
        lmod_gi_err {
          text
        }
        next_billing_date {
          text
          raw
        }
        no_invoice_message {
          text
          raw
        }
        note {
          text
          raw
        }
        page_description {
          text
        }
        page_author {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`

export default withPreview(PaymentHistoryPage)
