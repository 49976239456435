import { formatDateTime, DATETIME_FORMAT } from "@lesmills/gatsby-theme-common"

export const SORTING_ICON = {
  desc: "fa-caret-down",
  asc: "fa-caret-up",
}
export const DEFAULT_SORTING_TYPE = "desc"

export const getInvoicesStartDate = () => {
  let startDate = ""
  const now = new Date(Date.now())

  startDate = now.setFullYear(now.getFullYear() - 1)

  return formatDateTime(startDate, DATETIME_FORMAT.internalStandard)
}

export const sortInvoices = (invoices, type) =>
  invoices.sort((a, b) =>
    type === DEFAULT_SORTING_TYPE
      ? new Date(b.issue_date).getTime() - new Date(a.issue_date).getTime()
      : new Date(a.issue_date).getTime() - new Date(b.issue_date).getTime()
  )
