import React, { memo, Node } from "react"
import { Link } from "gatsby"
import { Icon } from "@lesmills/gatsby-theme-common"

type Props = {|
  title: string,
  children: Node,
  backLink?: {
    url: string,
    name: string,
  },
  classNames?: {
    wrapper: string,
  },
  handleDisableLink?: () => void | Promise<void>,
  disabled?: boolean,
|}

const Container = ({
  title,
  children,
  backLink,
  classNames = {},
  handleDisableLink,
  disabled,
}: Props) => (
  <div className={`mr-auto ml-auto px-20 md:px-0${classNames.wrapper}`}>
    {backLink && backLink.url ? (
      <Link
        to={backLink.url}
        data-cy="back-link"
        className={`text-base md:text-2lg flex justify-start md:leading-7none leading-snug font-base-light block text-gray-800 mt-28${
          disabled ? " disabled" : ""
        }`}
        onClick={handleDisableLink}
      >
        <Icon icon="black-back" type="medium" />
        <span className="md:inline hidden">{backLink.name}</span>
      </Link>
    ) : null}
    {backLink && !backLink.url ? (
      <div
        onClick={handleDisableLink}
        data-cy="back-link"
        className={`text-base underline cursor-pointer md:text-2lg flex justify-start md:leading-7none leading-snug font-base-light block text-gray-800 mt-28${
          disabled ? " disabled" : ""
        }`}
      >
        <Icon icon="black-back" type="medium" />
        <span className="md:inline hidden">{backLink.name}</span>
      </div>
    ) : null}
    <h2
      className={`font-base-black md:text-9xl text-6xl leading-4none md:mt-35 mt-4 text-gray-800`}
      data-cy="title-container"
    >
      {title}
    </h2>
    {children}
  </div>
)

export default memo(Container)
